import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next"
import { useLocalization } from "gatsby-theme-i18n"
import { Link } from "gatsby";
import LayoutTwo from "../components/layoutTwo";
import Seo from "../components/seo"

const ThankYou = () => {
  const { t } = useTranslation()
  const { locale } = useLocalization()
  let mylocale = ''
  if(locale == "ar")
    mylocale = "/ar" 
  return(
  <LayoutTwo>
    <div className="layout-padding-top">
      <div className="grey-bg">
        <div className="error-page-wrapper d-flex align-items-center">
          <Container>
            <Row className="d-flex justify-content-center">
              <Col xl={8} className="text-center">
                <h1>{t("thankyou_int")}</h1>
                <p>{t("member_contact")}</p>
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <Link to={`${mylocale}/`} className="button button-primary">{t("404_home_page")}</Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to={`${mylocale}/contact/`} className="button button-secondary-outline">{t("404_contact_us")}</Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  </LayoutTwo>
)}

export const Head = () => <Seo title="Thank you for your interest" />

export default ThankYou
